/**
 * Module dependencies
 */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import TrendEntry from '@seo-frontend-components/trends-entry';

import { UserContext } from '../context/user';

/**
 * CategoryTrendEntry Component
 */
const CategoryTrendEntry = (props) => {
  const { categoryTrend, deviceType, insideCarousel, preload } = props;
  const { loggedIn } = useContext(UserContext);

  return (
    <TrendEntry
      trend={categoryTrend}
      deviceType={deviceType}
      showImage={loggedIn}
      insideCarousel={insideCarousel}
      preload={preload}
    />
  );
};

CategoryTrendEntry.propTypes = {
  categoryTrend: PropTypes.shape({
    description: PropTypes.string.isRequired,
    images: PropTypes.arrayOf(
      PropTypes.shape({
        description: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      }),
    ).isRequired,
    keyword: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }),
  deviceType: PropTypes.string.isRequired,
  insideCarousel: PropTypes.bool,
  preload: PropTypes.bool,
};

CategoryTrendEntry.defaultProps = {
  insideCarousel: false,
  showImage: true,
};

/**
 * Inject i18n context as props into CategoryTrendEntry.
 */
export default CategoryTrendEntry;
